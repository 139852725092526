import { Suspense, lazy, useEffect, useState } from 'react'

export default function Zymscan() {

  const [Component, setComponent] = useState(() => Loading)

  useEffect(() => {
    // @ts-expect-error The type provided by @types/react is wrong
    setComponent(() => lazy(() => import('../components/query-client-form')))
  }, [])

  // workaround with join because seems cn util (twMerge) is removing the background color
  return (
    <section
      id="zymscan"
      className="bg-[#e7ecf0] py-28"
      // className={[
      //   'bg-[#e7ecf0] bg-shape-sm bg-contain bg-bottom bg-no-repeat pb-64 pt-14',
      //   'sm:pb-80 sm:pt-24',
      //   'md:bg-shape-lg md:pb-[26rem]',
      //   'lg:pb-[30rem] lg:pt-32',
      //   'xl:bg-shape-xl xl:pb-[42rem]',
      //   '2xl:bg-shape-2xl 2xl:pb-[56rem] 2.5xl:pb-[68rem]',
      //   '3xl:bg-shape-3xl', '4xl:bg-shape-4xl', '5xl:bg-shape-5xl' // We need such a screen to test
      // ].join(' ')}
    >
      <div className="container relative mx-auto px-4 font-roboto sm:px-8">
        <div className="flex flex-col items-center space-y-5 text-center">
          <h2 className="font-space-grotesk text-5xl font-bold">
            <span className="hidden lg:inline">Find the perfect enzyme for your reaction</span>
	          <span className="lg:hidden">Check out what ZymScan can do!</span>
          </h2>
        </div>
        <Suspense fallback={<Loading />}>
          <Component />
        </Suspense>
      </div>
    </section>
  )
}

function Loading() {
  return <div>Loading...</div>
}
