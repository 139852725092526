// import Header from '../../sections/header'
// import Instructions from '../../sections/instructions'
import Zymscan from '../../sections/zymscan'

function Page() {
  return (
    <>
      {/* <Header />
      <Instructions /> */}
      <Zymscan />
    </>
  )
}

export default Page
